import check from 'assets/check.png';

import NoAd from 'assets/no-ad.svg';
import searchOrder from 'assets/search-order.png';
import axios from 'axios';
import { memo, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList } from 'react-window';
import { convertHexToRgba } from 'utils';
import { sendMessageToSDK } from 'utils/send-message-to-sdk';
import {
  completeCampaign,
  getCPMCampaignList,
  getCampaignList,
  getRVInfoList,
  participateCampaign,
} from '../apis';
import RVFeedBanner from '../assets/rv-feed-banner.png';
import { BottomSheet, Footer, Modal, RcpcAd } from '../components';
import { RVAd } from '../components/RV-ad';
import { useErrorModal } from '../context';
import {
  ConvertCampaignFormat,
  convertCampaignParameter,
  isToday,
  numberWithCommas,
} from '../utils';

import CryptoJS from 'crypto-js';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  NoAdModalState,
  RVInfoListState,
  blockedCampaignList,
  campaignListState,
  cashNoteState,
  choiceOfferCampaignState,
  completedRcpcList,
  cpmCampaignListState,
  currencyState,
  customState,
  isTestState,
  isViewModeState,
  mediaKeyState,
  parameterState,
  platformState,
  selectedCampaignState,
  selectedTabState,
  tabListState,
  totalRewardState,
} from '../recoil/atoms/campaignState';

export function CampaignList({ checkInstalledPackageList, checkOrJoinCampaign }) {
  const { openModal, closeModal } = useErrorModal();
  const navigate = useNavigate();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const listRef = useRef(null);
  const [showChoiceOffer, setShowChoiceOffer] = useState(false);
  const [show501Error, setShow501Error] = useState(false);

  const isRewardVideoIntegrated = queryParams.get('isRewardVideoIntegrated') === 'true';

  const [isLoading, setIsLoading] = useState(false);

  // const [installedCampaignKeys, setInstalledCampaignKeys] = useRecoilState(installedCampaignList);
  const [blockedCampaignKeys, setBlockedCampaignKeys] = useRecoilState(blockedCampaignList);
  const [rcpcCampaign, setRcpcCampaign] = useRecoilState(completedRcpcList);

  const [tabList, setTabList] = useRecoilState(tabListState);
  const [isTest, setIsTest] = useRecoilState(isTestState);
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);
  const [campaignList, setCampaignList] = useRecoilState(campaignListState);
  const [rvInfoList, setRVInfoList] = useRecoilState(RVInfoListState);
  const [cpmCampaignList, setCpmCampaignList] = useRecoilState(cpmCampaignListState);
  const [totalReward, setTotalReward] = useRecoilState(totalRewardState);
  const [choiceOffer, setChoiceOffer] = useRecoilState(choiceOfferCampaignState);
  const selectedCampaign = useRecoilValue(selectedCampaignState);
  const [showNoAdModal, setShowNodAdModal] = useRecoilState(NoAdModalState);

  const [currency, setCurrency] = useRecoilState(currencyState);
  const currencyString = currency || 'P';

  const [selectedOption, setSelectedOption] = useState(0);
  const [showOptionSheet, setShowOptionSheet] = useState(false);

  const mediaKey = useRecoilValue(mediaKeyState);
  const parameter = useRecoilValue(parameterState);
  const platform = useRecoilValue(platformState);
  const custom = useRecoilValue(customState);
  const isCashNote = useRecoilValue(cashNoteState);
  const isViewMode = useRecoilValue(isViewModeState);

  const [modalContent, setModalContent] = useState(null);

  function filterObjectByCondition(obj, condition) {
    const filteredObject = {};

    // 객체의 모든 키에 대해 순회
    for (const key in obj) {
      // 객체의 속성인지 확인하고, 조건을 만족하는지 확인
      if (Object.hasOwnProperty.call(obj, key) && condition(obj[key])) {
        filteredObject[key] = obj[key]; // 조건을 만족하는 속성을 새 객체에 추가
      }
    }

    return filteredObject;
  }

  const newObj = localStorage.getItem('blockedCampaignList')
    ? filterObjectByCondition(JSON.parse(localStorage.getItem('blockedCampaignList')), isToday)
    : {};

  const installedCampaignList = localStorage.getItem('installedCampaigns')
    ? JSON.parse(localStorage.getItem('installedCampaigns'))
    : [];

  const [blockedCampaigns, setBlockedCampaigns] = useState(newObj);

  const [installedCampaignKeys, setInstalledCampaignKeys] = useState(installedCampaignList);

  useEffect(() => {
    localStorage.setItem('blockedCampaignList', JSON.stringify(blockedCampaigns));
  }, []);

  const CPMTabInfo = tabList?.filter((tab) => tab.IncludeParticipationNo.includes('43'))?.[0];

  const handleScroll = ({ scrollOffset }) => {
    if (selectedTab?.selectedTabId === 2) {
      setShowChoiceOffer(scrollOffset > 160);
    }
  };
  const showVideo = () => {
    // window?.APRewardOfferwall?.showRewardVideo();
    sendMessageToSDK('showRewardVideo', null, platform);
  };

  useEffect(() => {
    const nativeResponse = async (event) => {
      console.log(event?.detail?.data, 'nativeEvent');
      const eventResult = event?.detail?.data;

      if (eventResult.EventName === 'campaignCompleted') {
        const newItem = eventResult.EventParam;
        if (!newItem) return;
        setInstalledCampaignKeys((prev) => {
          if (!prev) {
            localStorage.setItem('installedCampaigns', JSON.stringify([]));
            return [];
          }

          // 새로운 배열로 필터링
          const updatedKeys = prev?.filter((el) => el !== newItem?.toString());

          // 로컬 스토리지에서 해당 항목 제거
          if (newItem) {
            localStorage.setItem('installedCampaigns', JSON.stringify(updatedKeys));
          }

          // 상태 업데이트
          return updatedKeys;
        });
        setBlockedCampaignKeys((prev) => [...prev, { type: 'campaign', key: newItem?.toString() }]);
      }

      if (eventResult.EventName === 'cpmCampaignCompleted') {
        const newItem = eventResult.EventParam;
        if (!newItem) return;
        setBlockedCampaignKeys((prev) => [...prev, { type: 'content', key: newItem?.toString() }]);
      }

      if (
        eventResult.EventName === 'newsCampaignCompleted' ||
        eventResult.EventName === 'webtoonCampaignCompleted'
      ) {
        const newItem = eventResult.EventParam;
        if (!newItem) return;
        const currentDate = new Date();

        const updatedList = { ...blockedCampaigns, [newItem]: currentDate.toISOString() };
        setBlockedCampaigns(updatedList);
        localStorage.setItem('blockedCampaignList', JSON.stringify(updatedList));
      }
      if (eventResult.EventName === 'showDetailPage' && eventResult.Result) {
        if (
          selectedCampaign.PartiTypeCode === 7 ||
          selectedCampaign.PartiTypeCode === 23 ||
          selectedCampaign.PartiTypeCode === 6
        ) {
          const updatedList = selectedCampaign?.Key
            ? [...new Set([...installedCampaignKeys, selectedCampaign.Key])]
            : installedCampaignKeys;
          setInstalledCampaignKeys(updatedList);
          localStorage.setItem('installedCampaigns', JSON.stringify(updatedList));
        }

        const openURL = isTest
          ? 'https://stage.offerwall.adpopcorn.com'
          : 'https://offerwall.adpopcorn.com';

        if (isViewMode) {
          return sendMessageToSDK(
            'openNewInAppBrowser',
            {
              url: `${openURL}/SDK/detail?auth=${selectedCampaign?.Auth}&isTest=${isTest}&platform=${platform}&mediakey=${mediaKey}`,
            },
            platform
          );
        }

        navigate('/SDK/detail', {
          state: {
            campaign: selectedCampaign,
          },
        });
      }

      if (eventResult.EventName === 'showRewardVideo') {
        showVideo();
      }

      if (eventResult.EventName === 'rcpcCampaignCompleted') {
        const scriptId = eventResult.EventParam;
        if (!scriptId) return;
        setRcpcCampaign((prev) => [...prev, scriptId]);
      }

      if (eventResult.EventName === 'rewardVideoCampaignCompleted') {
        if (!isRewardVideoIntegrated) return;
        const rewardVideoPlacementId = eventResult.EventParam;
        const RVParams = {
          mediaKey,
          adid: parameter?.adid || null,
          idfa: parameter?.idfa || null,
          usn: parameter?.usn || null,
        };
        try {
          const rvInfoListData = await getRVInfoList(RVParams, isTest);
          const rewardVideoInfo = rvInfoListData?.RewardVideoInfo || [];

          const updatedRv = rewardVideoInfo?.find(
            (newRv) => newRv?.IntegrationPlacementId === rewardVideoPlacementId
          );

          // 새로운 배열로 기존 배열 업데이트하기
          const updatedRVInfoList = rvInfoList?.map((rv) => {
            // 새로운 배열에서 IntegrationPlacementId가 일치하는 객체 찾기

            // IntegrationPlacementId가 일치하는 객체를 찾은 경우에만 업데이트
            if (rv?.IntegrationPlacementId === rewardVideoPlacementId) {
              // 기존 객체를 복사하여 업데이트된 정보로 변경
              return {
                ...rv,
                status: 'failed',
                IsAvailable: updatedRv?.IsAvailable,
                AvailableDateTime: updatedRv?.AvailableDateTime,
                CompleteDateTime: updatedRv?.CompleteDateTime,
                // 필요한 경우 다른 필드도 업데이트
              };
            } else {
              // IntegrationPlacementId가 일치하는 객체를 찾지 못한 경우 기존 객체 그대로 반환
              return rv;
            }
          });
          setRVInfoList(updatedRVInfoList);
        } catch (e) {
          openModal(e.message || '');
          setIsLoading(false);
        }
      }

      if (eventResult.EventName === 'OnRewardVideoAdLoaded') {
        const rewardVideoPlacementId = eventResult.EventParam;
        const floatingRV = rvInfoList?.find(
          (item) =>
            item?.IntegrationPlacementId === rewardVideoPlacementId && item?.PlacementTypeNo === 1
        );

        if (floatingRV) {
          sendMessageToSDK(
            'joinCampaign',
            { jsonParameter: `"${JSON.stringify(convertCampaignParameter(floatingRV))}"` },
            platform
          );
        }

        const newVal = rvInfoList?.map((rv) => {
          if (rv?.IntegrationPlacementId === rewardVideoPlacementId) {
            return { ...rv, status: 'success' };
          }
          return rv;
        });

        setRVInfoList(newVal);
      }

      if (eventResult.EventName === 'OnRewardVideoAdLoadFailed') {
        const rewardVideoPlacementId = eventResult.EventParam;

        // const newVal = rvInfoList.map((rv) => {
        //   if (rv.IntegrationPlacementId === rewardVideoPlacementId) {
        //     return { ...rv, status: 'failed' };
        //   }
        //   return rv;
        // });

        const filteredList = rvInfoList?.filter(
          (rv) => rv?.IntegrationPlacementId !== rewardVideoPlacementId
        );

        setRVInfoList(filteredList);
      }

      if (eventResult.EventName === 'installFail') {
        setModalContent({
          ResultMessage: eventResult.ResultMessage || '',
          left: { text: '닫기', func: () => setModalContent(null) },
          right: {
            text: '참여하기',
            func: () => {
              // window?.APRewardOfferwall?.joinCampaign(
              //   `"${JSON.stringify(convertCampaignParameter(selectedCampaign))}"`
              // );
              sendMessageToSDK(
                'joinCampaign',
                {
                  jsonParameter: `"${JSON.stringify(convertCampaignParameter(selectedCampaign))}"`,
                },
                platform
              );
              setModalContent(null);
            },
          },
        });
      }

      if (eventResult.EventName === 'checkCampaignPackageTargetList' && eventResult.Result) {
        checkOrJoinCampaign(selectedCampaign);
      }
    };

    window.addEventListener('NativeEvent', nativeResponse);

    return () => {
      window.removeEventListener('NativeEvent', nativeResponse);
    };
  }, [selectedCampaign, rvInfoList]);

  useEffect(() => {
    async function fetch() {
      try {
        setIsLoading(true);
        // const campaignListData = await getCampaignList(mockData);
        const campaignListData = await getCampaignList({ ...parameter, app_key: mediaKey });

        //test 모드일 경우 sdk 전달
        // window?.APRewardOfferwall?.isTestMode(campaignListData.IsTest);
        sendMessageToSDK('isTestMode', { enable: campaignListData.IsTest }, platform);
        setIsTest(campaignListData.IsTest);

        if (!campaignListData.Result) {
          sendMessageToSDK('onOfferwallLoadFailed', null, platform);
          if (campaignListData?.ResultCode === 501) {
            setShow501Error(true);
            return openModal(
              "'광고 추적 제한' 설정시 광고 참여가 제한됩니다.\n 설정-> 개인정보 보호 -> 추적 메뉴에서 해당 앱의 추적을 허용해 주십시오"
            );
          }
          return setShowNodAdModal(true);
        }

        const convertedCampaignList = ConvertCampaignFormat(tabList, campaignListData);

        // window?.APRewardOfferwall?.onOfferwallLoadSuccess();
        sendMessageToSDK('onOfferwallLoadSuccess', null, platform);

        setChoiceOffer(
          convertedCampaignList?.[0]?.campaigns?.find((campaign) => campaign?.IsChoiceOffer) || null
        );

        setIsLoading(false);
        setCampaignList(convertedCampaignList);
        setTotalReward(campaignListData?.TotalRewardQuantity || 0);
        // if (window?.APRewardOfferwall?.setTotalReward) {
        //   window?.APRewardOfferwall?.setTotalReward(campaignListData?.TotalRewardQuantity);
        // }
        sendMessageToSDK(
          'setTotalReward',
          { totalReward: campaignListData?.TotalRewardQuantity },
          platform
        );

        sendMessageToSDK(
          'setRewardCurrency',
          { currency: campaignListData?.RewardCurrency },
          platform
        );
        setCurrency(campaignListData?.RewardCurrency || null);

        await fetchRVInfoList(campaignListData.IsTest);
      } catch (e) {
        sendMessageToSDK('onOfferwallLoadFailed', null, platform);
        setIsLoading(false);
        if (e?.code === 501) {
          setShow501Error(true);
          return openModal(
            "'광고 추적 제한' 설정시 광고 참여가 제한됩니다.\n 설정-> 개인정보 보호 -> 추적 메뉴에서 해당 앱의 추적을 허용해 주십시오"
          );
        } else if (e?.code === 980) {
          return openModal(e.message || '');
        } else setShowNodAdModal(true);
      }
    }

    if (campaignList?.length) return;
    if (!parameter) return;

    fetch();
  }, [parameter]);

  async function fetchRVInfoList(isTest) {
    if (!isRewardVideoIntegrated || platform === 'ios') return;

    const RVParams = {
      mediaKey,
      adid: parameter?.adid || null,
      idfa: parameter?.idfa || null,
      usn: parameter?.usn || null,
    };

    try {
      const rvInfoListData = await getRVInfoList(RVParams, isTest);

      const rewardVideoInfo = rvInfoListData?.RewardVideoInfo || [];

      setRVInfoList(
        rewardVideoInfo?.map((rv) => {
          return { ...rv, status: 'loading' };
        })
      );

      rewardVideoInfo
        ?.filter?.((rv) => rv?.PlacementTypeNo === 0)
        ?.forEach((rv) => {
          sendMessageToSDK(
            'loadRewardVideo',
            {
              appKey: rv?.IntegrationAppKey || '',
              placementId: rv?.IntegrationPlacementId || '',
              showProgress: false,
            },
            platform
          );
        });
    } catch (e) {
      return;
    }
  }

  const { selectedSubTabData, selectedTabId } = selectedTab;

  const selectedTabInfo = campaignList?.find((tab) => tab.TypeNo === selectedTabId);

  useEffect(() => {
    if (
      selectedTabInfo?.SubTabInfo?.length > 0 &&
      !selectedTabInfo?.SubTabInfo?.find((tab) => tab.TypeNo === selectedSubTabData[selectedTabId])
    ) {
      const subTab = tabList
        .find((tab) => tab.TypeNo === selectedTabId)
        ?.SubTabInfo?.find((subTab) => subTab.IncludeParticipationNo.includes('0'));

      if (subTab) {
        return setSelectedTab((prev) => {
          return {
            ...prev,
            selectedSubTabData: {
              ...prev.selectedSubTabData,
              [prev.selectedTabId]: subTab?.TypeNo,
            },
          };
        });
      }
    }
  }, [selectedTabInfo]);

  useEffect(() => {
    async function fetch() {
      try {
        if (selectedTabId === CPMTabInfo?.TypeNo) {
          const cpmCampaignListData = await getCPMCampaignList(
            { ...parameter, app_key: mediaKey },
            isTest
          );

          const cpmCampaigns = [
            ...(cpmCampaignListData?.CPMCampaigns || []),
            ...(cpmCampaignListData?.RCPCCampaigns || []),
            ...(cpmCampaignListData?.CPCCampaigns || []),
          ];

          setCpmCampaignList(cpmCampaigns);

          const filteredCampaigns =
            cpmCampaigns?.filter((campaign) => campaign?.IntegrationTypeCode === 46) || [];

          await Promise.all(
            filteredCampaigns?.map(async (campaign) => {
              const impUrl = campaign?.ImpressionURLs?.length ? campaign?.ImpressionURLs[0] : null;
              if (impUrl) {
                try {
                  await axios.get(impUrl); // 반환 없이 GET 요청만 수행
                } catch (error) {
                  console.error(`Failed to fetch URL: ${impUrl}`, error);
                }
              }
            })
          );
        }
      } catch (e) {
        sendMessageToSDK('onOfferwallLoadFailed', null, platform);
      }
    }

    if (cpmCampaignList.length > 0) return;
    if (!parameter) return;

    fetch();
  }, [selectedTabId, parameter]);

  const selectedCampaigns =
    selectedTabId === CPMTabInfo?.TypeNo
      ? cpmCampaignList
      : selectedTabInfo?.campaigns ||
        selectedTabInfo?.SubTabInfo?.find(
          (subTab) => subTab.TypeNo === selectedSubTabData[selectedTabId]
        )?.campaigns ||
        [];

  const getFilteredSelectedCampaigns = () => {
    const rvListAd = rvInfoList?.filter((rv) => rv.PlacementTypeNo === 0)?.[0];
    let filteredSelectedCampaigns = (selectedCampaigns || [])
      ?.filter((el) => !Object.keys(blockedCampaigns).includes(el?.Id?.toString()))
      ?.filter(
        (el) =>
          !blockedCampaignKeys.some((blockedCampaign) => {
            return (
              blockedCampaign.key ===
              (blockedCampaign.type === 'campaign' ? el?.Key?.toString() : el?.Id?.toString())
            );
          })
      );

    filteredSelectedCampaigns = filteredSelectedCampaigns.sort((a, b) => {
      const aValue = a?.RewardDetail?.Quantity || a.Reward || 0;
      const bValue = b?.RewardDetail?.Quantity || b.Reward || 0;

      if (selectedOption === 2) {
        return bValue - aValue || b?.RegistUnixTime - a?.RegistUnixTime; // 내림차순 정렬
      } else if (selectedOption === 3) {
        return aValue - bValue || b?.RegistUnixTime - a?.RegistUnixTime; // 오름차순 정렬
      } else if (selectedOption === 1) {
        return b?.RegistUnixTime - a?.RegistUnixTime;
      } else {
        return 0; // 추천순, 정렬하지 않음
      }
    });
    if (rvListAd && rvListAd?.IsAvailable) {
      const index = selectedTabId === 2 ? rvListAd.DisplayPriority - 1 : 0;
      filteredSelectedCampaigns?.splice(index, 0, rvListAd);
    }

    return filteredSelectedCampaigns;
  };

  const selectedCampaignList = getFilteredSelectedCampaigns();

  useEffect(() => {
    if (listRef?.current) {
      listRef.current?.scrollToItem(0); // 내부 컨테이너의 스크롤을 맨 위로 이동
    }
  }, [selectedTab]);

  useEffect(() => {
    listRef.current && listRef.current.resetAfterIndex(0);
  }, [selectedCampaignList]);

  const handleClickRVAd = (rvListAd) => {
    if (rvListAd?.status === 'loading') {
      return openModal('광고가 준비중이에요!\n1분 후 다시 참여해주세요.', closeModal);
    }

    if (rvListAd?.status === 'failed') {
      return sendMessageToSDK(
        'loadRewardVideo',
        {
          appKey: rvListAd?.IntegrationAppKey || '',
          placementId: rvListAd?.IntegrationPlacementId || '',
          showProgress: true,
        },
        platform
      );
    }

    if (rvListAd?.status === 'success') {
      // window?.APRewardOfferwall?.joinCampaign(
      //   `"${JSON.stringify(convertCampaignParameter(rvListAd))}"`
      // );

      sendMessageToSDK(
        'joinCampaign',
        { jsonParameter: `"${JSON.stringify(convertCampaignParameter(rvListAd))}"` },
        platform
      );
    }
  };

  const handleClickNasCampaign = async (campaign) => {
    const rewardString = campaign?.Reward || null;
    const parsedReward =
      typeof rewardString === 'string' ? parseInt(rewardString, 10) : rewardString;

    const data = {
      IntegrationId: campaign?.IntegrationId || null,
      Id: campaign?.Id || null,
      Name: campaign?.Name || null,
    };

    // 객체의 값들을 배열로 변환한 후, join 메서드를 사용하여 콤마로 결합
    function combineValuesToString(obj) {
      // Check if any value in the object is null
      if (Object.values(obj).some((value) => value === null)) {
        return null;
      }

      // Join the values with a comma if none are null
      return Object.values(obj).join(',');
    }

    const customParam = combineValuesToString(data);
    if (!customParam) return openModal('참여에 필요한 필수정보가 없습니다.');

    const params = {
      participation_type_no: campaign?.PartiTypeCode || null,
      auth: campaign?.Auth || null,
      network: parameter?.network || null, // 참여적립
      app_key: parameter?.app_key || null, // 간편/뉴스/웹툰 적립
      integration_type_no: campaign?.IntegrationTypeCode || null, // 간편/뉴스/웹툰 적립
      usn: parameter?.usn || null, // 간편/뉴스/웹툰 적립
      adid: parameter?.adid || null, // 간편/뉴스/웹툰 적립
      idfa: parameter?.idfa || null, // 간편/뉴스/웹툰 적립
      point: parsedReward, // 간편/뉴스/웹툰 적립
      contents_id: null, // 뉴스/웹툰 적립 (뉴스, 웹툰 ID)
      custom_param: customParam,
    };

    // const impUrl = campaign?.ImpressionURLs?.length ? campaign?.ImpressionURLs[0] : null;

    // 특정 조건 체크
    try {
      // const impRes = await axios.get(impUrl);
      // console.log(impRes, '<=====step1');
      const response = await participateCampaign(params, isTest);
      console.log(response, '<=====step2');
      if (response.Result) {
        const auth = response?.Auth || null;
        const sign = CryptoJS.HmacMD5(auth, campaign?.Key)?.toString() || null;
        const response2 = await completeCampaign(
          {
            participation_type_no: campaign?.PartiTypeCode || null,
            auth,
            sign,
            network: parameter?.network || null,
          },
          isTest
        );
        console.log(response2, '<=====step3');
        if (response2.Result) {
          setBlockedCampaignKeys((prev) => [
            ...prev,
            { type: 'content', key: campaign?.Id?.toString() },
          ]);
          if (response?.RedirectURL) {
            sendMessageToSDK('openWebBrowser', { url: response?.RedirectURL }, platform);
          }
        }
      }
    } catch (error) {
      console.log(error);
      return openModal(error.message || '');
    }
  };

  if (showNoAdModal && !isViewMode)
    return (
      <Modal
        isOpen={showNoAdModal}
        modalContent={{
          ResultMessage:
            '참여 가능한 광고가 없습니다. 자세한 안내를 보시려면 아래 이동 버튼을 눌러주세요.',
          left: {
            text: '닫기',
            func: () => {
              // window?.APRewardOfferwall?.closeOfferwall();

              sendMessageToSDK('closeOfferwall', null, platform);
            },
          },
          right: {
            text: '이동',
            func: () => navigate('/cs'),
          },
        }}
      />
    );

  const hasRVFloatingAd = rvInfoList?.filter((rv) => rv.PlacementTypeNo === 1)?.[0];

  const Row = memo(
    (props) => {
      const { data, index, style } = props;
      const campaign = selectedCampaignList?.[index];

      return (
        <div style={style}>
          {campaign?.Key === '958189455' ? (
            <RcpcAd campaign={campaign} />
          ) : campaign?.Key === '559551593' ? (
            <div
              className="w-full rounded-[10px] shadow-card"
              role="presentation"
              key={index}
              onClick={() => handleClickRVAd(campaign)}
            >
              <img
                className="w-full rounded-t-[10px] object-cover"
                alt="비디오 광고 이미지"
                src={RVFeedBanner}
              />

              <div className="p-[16px] flex justify-between items-center">
                <div className="flex flex-col gap-[2px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
                  <div className="text-[14px] text-gray-900 leading-[19.6px] truncate">
                    영상 보고 포인트 적립하기
                  </div>
                  <div className="text-[13px] text-gray-500 leading-[15.6px] truncate">
                    동영상을 시청만 하면 포인트를 드려요
                  </div>
                </div>
                {isCashNote ? (
                  <div className="flex justify-center">
                    <span
                      className={`text-14 text-[#2474EE] font-bold rounded-[8px] min-w-73 px-6 py-4 break-keep whitespace-pre flex justify-center items-center bg-[#F3FAFF] self-center`}
                    >
                      {`${campaign?.Point}${currencyString} 지급`}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: convertHexToRgba(custom.primaryColor),
                      color: custom.primaryColor,
                    }}
                    className={`text-[14px] rounded-[6px] px-[12px] py-[8px] break-keep min-w-[60px] flex justify-center items-center`}
                  >
                    <span>{`${campaign?.Point}${currencyString}`}</span>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              className="w-full rounded-[10px] shadow-card"
              role="presentation"
              key={index}
              onClick={() => {
                let eventName = 'impression_offerwall_detail_page';
                let eventParam = '';

                /** 참여적립의 경우 */
                if (selectedTabId === 2) {
                  eventName = 'impression_offerwall_detail_page_participation';
                  eventParam = campaign?.Key || '';
                } else if (selectedTabId === 4) {
                  /** 쇼핑 적립의 경우 */
                  eventName = 'impression_offerwall_detail_page_shopping';
                  eventParam = campaign?.Key || '';
                } else if (selectedTabId === 3) {
                  /** 클릭 적립의 경우 */
                  eventParam = campaign?.Key || '';
                } else if (selectedTabId === 0) {
                  eventParam = '0';
                } else {
                  const selectedSubTabData = selectedTab?.selectedSubTabData;
                  const selectedTabId = selectedTab?.selectedTabId;
                  eventParam =
                    selectedSubTabData && selectedTabId ? selectedSubTabData[selectedTabId] : '';
                }

                sendMessageToSDK(
                  'logging',
                  {
                    eventName: eventName,
                    eventParam: eventParam,
                  },
                  platform
                );
                if (campaign?.IntegrationTypeCode === 46) {
                  return handleClickNasCampaign(campaign);
                }
                checkInstalledPackageList(campaign);
              }}
            >
              {campaign.FeedMainImgURL || campaign.ImageUrl ? (
                <img
                  className="w-full rounded-t-[10px] object-cover"
                  alt={campaign.Title}
                  src={campaign.FeedMainImgURL || campaign.ImageUrl || ''}
                  style={{ height: style?.height ? style?.height - 80 : 173 }}
                />
              ) : (
                <div
                  className="w-full rounded-t-[10px] bg-gray-300"
                  style={{
                    height: style?.height ? style?.height - 80 : 173,
                  }}
                />
              )}
              <div className="p-[16px] flex justify-between items-center">
                <div className="flex flex-col gap-[2px] w-full whitespace-nowrap overflow-hidden text-ellipsis">
                  <div className="text-[14px] text-gray-900 leading-[19.6px] truncate">
                    {campaign.Title}
                  </div>
                  <div className="text-[13px] text-gray-500 leading-[15.6px] truncate">
                    {campaign.Desc || campaign.Description || ''}
                  </div>
                </div>
                {isCashNote ? (
                  <div className="flex justify-center">
                    <span
                      className={`text-14 text-[#2474EE] font-bold rounded-[8px] min-w-73 px-6 py-4 break-keep whitespace-pre flex justify-center items-center bg-[#F3FAFF] self-center`}
                    >
                      {installedCampaignKeys?.includes(campaign.Key) ? (
                        <span className="text-nowrap">설치 확인</span>
                      ) : (
                        <span>
                          {typeof campaign.Reward === 'string'
                            ? `${campaign?.Reward} 지급`
                            : `${campaign.Reward}${currencyString} 지급`}
                        </span>
                      )}
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      backgroundColor: convertHexToRgba(custom.primaryColor),
                      color: custom.primaryColor,
                    }}
                    className={`text-[14px] rounded-[6px] px-[12px] py-[8px] break-keep min-w-[60px] flex justify-center items-center`}
                  >
                    {installedCampaignKeys?.includes(campaign.Key) ? (
                      <span className="text-nowrap">설치 확인</span>
                    ) : (
                      <span>
                        {typeof campaign.Reward === 'string'
                          ? campaign.Reward
                          : `${campaign.Reward}${currencyString}`}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      );
    },
    (prevProps, nextProps) => JSON.stringify(prevProps) === JSON.stringify(nextProps)
  );

  Row.displayName = 'Row';

  const options = [
    { label: '추천순', value: 0 },
    { label: '최신순', value: 1 },
    { label: `${currencyString} 높은 순`, value: 2 },
    { label: `${currencyString} 낮은 순`, value: 3 },
  ];

  return (
    <div className={`px-[15px] flex flex-col w-full h-full overflow-hidden overscroll-none`}>
      {hasRVFloatingAd && <RVAd />}
      {modalContent && <Modal isOpen={true} modalContent={modalContent} />}
      {choiceOffer && showChoiceOffer && selectedTab?.selectedTabId === 2 && (
        <div
          className="flex left-0 p-[15px] absolute w-full shadow-card bg-white items-center justify-between z-20"
          role="presentation"
          onClick={() => checkInstalledPackageList(choiceOffer)}
        >
          <div className="flex gap-[8px]">
            <img
              src={choiceOffer?.IconImgURL || ''}
              alt="초이스오퍼 아이콘 이미지"
              className="w-[40px] h-[40px] rounded-[6px]"
            />
            <div className="flex flex-col">
              <span className="text-[14px] text-[#212529] leading-[18.2px]">
                {choiceOffer?.FeedTitle || ''}
              </span>
              <span className="text-[13px] text-[#808C99] leading-[15.6px]">
                {choiceOffer?.FeedDesc || ''}
              </span>
            </div>
          </div>
          <div
            style={{
              backgroundColor: custom.primaryColor,
            }}
            className={`h-[28px] py-[4px] px-[11px] rounded-[6px] text-[14px] text-white leading-[19.6px]`}
          >
            {choiceOffer?.Reward}
          </div>
        </div>
      )}

      <div className="flex text-gray-800 text-[13px] px-[15px] py-[12px] leading-[18.2px] justify-between items-start">
        <div className="flex gap-[2px] relative">
          총
          <div>
            {numberWithCommas(totalReward)}
            {currencyString}
          </div>
          적립 가능
        </div>
        <button
          className="flex justify-center items-center"
          onClick={() => {
            setShowOptionSheet(true);
          }}
        >
          <span>{options?.find((el) => el.value === selectedOption)?.label}</span>
          <img src={searchOrder} alt="필터" className="size-13" />
        </button>
      </div>
      {showOptionSheet && (
        <BottomSheet
          onClose={(e) => {
            if (e.target === e.currentTarget) {
              setShowOptionSheet(false);
            }
          }}
          content={
            <div className=" text-gray-500 p-20 text-[14px] leading-[19.6px] gap-14 flex flex-col items-center font-regular">
              {options.map((option) => (
                <button
                  key={option.value}
                  className={`w-full h-24 flex justify-between ${
                    selectedOption === option.value && 'text-[#212529] font-medium'
                  }`}
                  onClick={() => {
                    setSelectedOption(option.value);
                    setShowOptionSheet(false);
                  }}
                >
                  <span className="self-center">{option.label}</span>
                  {selectedOption === option.value && (
                    <img src={check} alt="체크 아이콘" className="size-24" />
                  )}
                </button>
              ))}
            </div>
          }
        />
      )}
      <div className="grow mb-[20px]">
        {selectedCampaignList.length === 0 ? (
          <div className="flex flex-col gap-[16px] items-center h-full self-center justify-center">
            <img src={NoAd} alt="시계" width={72} height={72} />
            <div className="flex flex-col items-center">
              <span className="text-[#394047] font-semibold text-[18px] leading-[28px]">
                {showNoAdModal || show501Error
                  ? '참여 가능한 광고가 없습니다.'
                  : '미션을 준비 중이에요'}
              </span>
              <span className="text-center whitespace-pre text-[14px] leading-[20px] text-[#808C99] font-normal">
                {`${
                  !isViewMode
                    ? '상단에 다른 미션들을 참여하고 \n리워드를 받아가세요'
                    : showNoAdModal
                    ? '현재 캠페인의 참여 한도가 소진되어 참여할 수 없습니다.'
                    : show501Error
                    ? '더 나은 맞춤형 광고와 리워드를 위해 \n추적을 활성화해 주세요.'
                    : '잠시 후 다시 확인 부탁드릴게요'
                }`}
              </span>
            </div>
          </div>
        ) : (
          <AutoSizer>
            {({ width, height }) => (
              <VariableSizeList
                ref={listRef}
                onScroll={handleScroll}
                height={height}
                width={width}
                itemData={selectedCampaignList}
                itemSize={(idx) => {
                  return selectedCampaignList?.[idx]?.Key === '958189455'
                    ? 120
                    : (window.innerWidth - 30) * 0.52 + 80;
                }}
                itemCount={selectedCampaignList?.length}
              >
                {Row}
              </VariableSizeList>
            )}
          </AutoSizer>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default CampaignList;
